<template>
  <div class="wrapper">
    <div class="main-content">
      <!-- 招聘会 -->
      <div class="content-job main-item">
        <div class="title">
          <span> 招聘会 </span>
          <router-link class="more" to="/jobfairsList"> 更多 </router-link>
        </div>
        <!-- 招聘会列表 -->
        <div class="job-item">
          <fair-list noMoreBg="#f0f0f0" :data="FairData" :noMore="false" />
        </div>
      </div>
      <!-- 招聘 -->
      <div class="content-job main-item">
        <div class="title">
          <span> 人才招聘 </span>
          <router-link class="more" to="/job"> 更多 </router-link>
        </div>
        <!-- 招聘列表 -->
        <div class="job-item">
          <job-list noMoreBg="#f0f0f0" :data="data" :noMore="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getIndexJobList } from "@/api/job";
import { getfairsList } from "@/api/jobfairs";
import { mapState } from "vuex";
import PolicyIcon from "@/components/PolicyIcon";
import JobList from "@/components/JobList";
import FairList from "@/components/FairList";
export default {
  data() {
    return {
      activeSerTab: 0,
      newList: [],
      swiperNewsList: [],
      todoList: {},
      todoHotList: [],
      data: [],
      FairData: [
        // {
        //   id: 4,
        //   jobfairCode: "1627958284929294337",
        //   jobfairName: "河南郑州航空港2023年“风华正茂港好有你”春季校园招聘会",
        //   beginTime: "2023-03-21 08:00:00",
        //   endTime: "2023-03-23 17:00:00",
        //   address: "河南省郑州市金水区CBD商务中心",
        //   posterCode: "1627591501700128770",
        //   delType: 1,
        //   updateTime: "2023-02-22 11:23:17",
        //   createTime: "2023-02-20 15:25:51",
        //   state: "2",
        //   cmpyNum: 0,
        //   jobNum: 0,
        //   recruitNum: 0,
        //   applyNum: 0,
        //   imgUrl: null,
        //   status: "未开始",
        //   usrNum: 0,
        // },
      ],
    };
  },
  watch: {},
  components: { PolicyIcon, JobList, FairList },
  computed: {
    ...mapState({
      isLogin: (state) => state.global.isLogin,
      userInfo: (state) => state.global.userInfo,
    }),
  },
  filters: {
    formatCardId(value) {
      if (!value) return "---";
      const cardID =
        value.substr(0, 3) + "***********" + value.substr(14, value.length);
      return cardID;
    },

    formatTime(val) {
      let date = val.split(" ")[0];
      let dateWithoutYear = date.split("-");
      return `${dateWithoutYear[1]}-${dateWithoutYear[2]}`;
    },
  },
  mounted() {
    this.getfairsLists();
    this.getJobList();
  },
  methods: {
    /* 招聘会列表 */
    getfairsLists() {
      const params = {
        apiName: "/recruit/jobfairs/list",
        paramMap: { pageNum: 1, pageSize: 2 },
        requestType: 1,
      };
      getfairsList(params).then((res) => {
        let { data, success, code } = res;

        if (success) {
          this.FairData = data.records;
        }
      });
    },
    /* 招聘列表 */
    getJobList() {
      const params = {
        apiName: "/recruit/job-info/getJobInfoList",
        paramMap: {
          pageSize: 6,
          pageNum: 1,
          cityName: "郑州市",
          proName: "河南省",
          districtName: "航空港区",
          lat: "34.52532",
          lng: "113.854726",
          type: 1, //根据地址从近到远
        },
        requestType: 1,
      };
      getIndexJobList(params).then((res) => {
        console.log(res);

        this.data = res.data.list;
      });
    },

    handleLogin() {
      this.$store.dispatch("global/authLogin");
    },

    /*
     *@des: 导航至办事项详情页
     */
    navToTodoDetail({ itemId }) {
      this.$router.push({
        path: `/guideDetail`,
        query: {
          itemId,
        },
      });
    },
    navToApplyList() {
      this.$router.push("/applylist");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  background: #f0f5f7;
  overflow: auto;
}

.main-content {
  padding: 0 0.3rem;
  .main-card {
    background: #ffffff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0.2rem;
    margin-bottom: 0.3rem;
    overflow: hidden;
    padding: 0.3rem;
  }
}
.news-swiper {
  height: 1.4rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  .swiper-wrapper {
    flex: 1;
    margin-left: 0.1rem;
    overflow: hidden;
    .notice-swiper {
      height: 0.4rem;
      width: 100%;
    }
    .notice-swiper-item {
      height: 0.4rem;
      font-size: 0.32rem;
      color: #616161;
      width: 100%;
      white-space: nowrap;
      word-break: keep-all;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.service-tabs {
  margin-top: 0.1rem;
  /deep/ .van-tabs__nav {
    align-items: baseline;
    border-bottom: 1px solid #f2f2f2;
    height: auto;
    padding-bottom: 0;
    .van-tabs__wrap {
      height: auto;
    }
    .van-tab {
      flex: unset;
      margin-bottom: 0.36rem;
    }
    .van-tab--active {
      font-size: 0.4rem;
      font-weight: 500;
      color: #333333;
    }
    .van-tabs__line {
      bottom: 0;
      width: 1.7rem;
      background-color: #333;
    }
  }
}

.user-content {
  display: flex;
  .progress-link {
    width: 2.6rem;
    background: #3c37d2;
    font-size: 0.4rem;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.user-info {
  margin-right: 0.3rem;
  flex: 1;
  .col-info {
    .row-name {
      font-size: 0.34rem;
      line-height: 0.36rem;
      color: #333333;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .row-id {
      margin-top: 0.2rem;
      font-size: 0.28rem;
      line-height: 0.3rem;
      color: #000;
    }
  }
  .col-progress {
    width: 1.7rem;
    height: 0.6rem;
    padding-left: 0.2rem;
    background: linear-gradient(
      48deg,
      #3c37d2 0%,
      #5954d6 50%,
      #f94363 90%,
      #ff4451 100%
    );
    box-shadow: 0.01rem 0.01rem 0.03rem 0rem rgba(103, 103, 103, 0.5);
    border-radius: 0.3rem 0rem 0rem 0.3rem;
    transform: translate(0.3rem, 0rem);
    font-size: 0.3rem;
    line-height: 0.6rem;
    color: #ffffff;
  }
}

.auth-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .remark {
    width: 3.9rem;
    font-size: 0.26rem;
    font-weight: 400;
    color: #999999;
  }
  .go-auth {
    font-size: 0.26rem;
    font-weight: 400;
    color: #3c37d2;
  }
}
.todo-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .todo-item {
    width: 3.35rem;
    height: 0.49rem;
    padding: 0rem 0.2rem;
    margin-top: 0.3rem;
    background: #f0f9fe;
    border-radius: 0.08rem;
    font-size: 0.28rem;
    font-weight: bold;
    color: #000;
    line-height: 0.49rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.todo-tab {
  /deep/ .van-tabs__wrap {
    .van-tabs__nav--line {
      padding-bottom: 0rem;
    }
    .van-tab--active {
      height: 0.38rem;
      border-bottom: 0.08rem solid #3c37d2;
      font-size: 0.34rem;
      color: #3f3ad2;
      font-weight: bold;
      white-space: nowrap;
    }
    .van-tab {
      display: block;
      width: auto;
      margin-right: 0.3rem;
    }
    .van-tabs__line {
      display: none;
    }
  }
}
.todo-wrapper {
  height: 2.6rem;
}
.content-job {
  .title {
    font-size: 0.4rem;
    font-weight: 600;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.4rem;
    .more {
      font-size: 0.28rem;
      color: #999999;
      font-weight: 400;
    }
  }
  .job-item {
    width: 100%;
    display: flex;
  }
}
.main-item {
  background: #ffffff;
  border-radius: 0.2rem;
  margin-bottom: 0.3rem;
  overflow: hidden;
  padding: 0.3rem;
}
</style>
